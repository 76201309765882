export default
{
  time_zone: 'America/Costa_Rica',
  locale: 'es-CR',
  currency: 'CRC',
  production: ('false' == 'true'),
  // Orígenes remotos para recibir mensajes vía postMessage
  remote_origins: JSON.parse('["https://suite.motelnowapp.com","https://suite.motelnow.com.co","https://suite.motelnow.cr"]'),
  // Listado de regiones o geografías soportadas
  regions: JSON.parse('[{"region_id":1,"region_name":"San José","region_latitude":0.0,"region_longitude":0.0},{"region_id":2,"region_name":"Heredia","region_latitude":0.0,"region_longitude":0.0}]'),
}
